<template>
  <page-section
      title="homepage.sections.possibilities.title"
      subtitle="homepage.sections.possibilities.text"
  >

    <v-row>
      <v-col
          v-for="(item , i) in items" :key="i"

          :cols=" !SM ? item.cols : 12"
      >
        <possibilityCard
            class="mt-4"
            :title="item.title"
            :text="item.text"
            :image="item.image"
            :text-height="item.cols < 12 ? '100px' : null"
            :image-height="item.cols < 12 ? '350px' : '655px'"
        />
      </v-col>
    </v-row>


  </page-section>
</template>

<script>
import possibilityCard from "@/components/pages/homepage_westudy/home/sections/UI/possibilityCard";
export default {
  name: "homeSectionPossibilities",
  components : {
    possibilityCard
  },
  data() {
    return {
      items : [
        { title : 'features.courseEditor.title',
          text : 'features.courseEditor.text',
          image : 'img/features/feature_1.png',
          cols : 12
        },
        // { title : 'features.courseEditor.title',
        //   text : 'features.courseEditor.text',
        //   image : 'sosochnik',
        //   cols : 12
        // },
        { title : 'features.notifications.title',
          text : 'features.notifications.text',
          image : 'img/features/feature_2.png',
          cols : 5
        },
        { title : 'features.evaluation.title',
          text : 'features.evaluation.text',
          image : 'img/features/feature_3.png',
          cols : 7
        },
        { title : 'features.newsletter.title',
          text : 'features.newsletter.text',
          image : 'img/features/feature_4.png',
          cols : 7
        },
        { title : 'features.certificates.title',
          text : 'features.certificates.text',
          image : 'img/features/feature_5.png',
          cols : 5
        }
      ]
    }
  }
}
</script>

<style scoped>

</style>